<template>
  <v-container
    fluid
    class="pa-0"
    style="height: 100vh; background-color: white"
  >
    <headerToolbar color="white">
      <template v-slot:toolbarTitle>
        <b class="ml-3">{{ $t("navigationMenu.followUps") }}</b>
        <v-dialog
          v-model="dialog"
          max-width="480"
          transition="dialog-top-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="px-2 button_blue white--text ml-6"
              depressed
              small
              @click="editFollowup({})"
            >
              <v-icon small class="pr-1">mdi-plus</v-icon>
              <span  >{{
                $t("headerActions.followUpForm")
              }}</span>
            </v-btn>
          </template>
          <template v-slot:default>
            <followUpForm
              @followUpCreated="onFollowUpCreated"
              @followUpUpdated="onFollowUpUpdated"
              @closeDialog="dialog = false"
              :editedFollowup="editedFollowup"
              :projects="projects"
              :projectsLoading="projectsLoading"
            />
          </template>
        </v-dialog>
      </template>
    </headerToolbar>

    <v-divider />
    <!-- <v-divider></v-divider> -->
    <followUpFormProject
      @followUpCreated="onFollowUpCreated"
      :editedFollowup="followupFormProjectFollowup"
    />

    <!-- No followups -->
    <v-container
      class="pa-4 ma-0"
      fill-height
      v-if="!search && noResourceFetched"
    >
      <v-row
        wrap
        no-gutters
        class="mx-auto pa-0 fill-height"
        style="max-width: 1280px; height: 100%"
      >
        <!-- <v-col cols="2">
        <projectsSidebar />
      </v-col> -->
        <template>
          <v-container fluid style="height: 100%" class="px-8 text-center">
            <v-row
              wrap
              no-gutters
              style="height: 100%"
              class="align-center justify-center"
            >
              <v-col cols="12" class="align-center justify-center">
                <v-col cols="12" class="text-center justify-center">
                  <img height="260" src="/images/followups.png" />
                  <h2 class="pb-4">
                    {{ $t("followUps.noFollowUps") }}
                  </h2>

                  <!-- <span class="body-2">
                  {{ $t("followUps.followUpsDescription1") }}<br />
                  {{ $t("followUps.followUpsDescription2") }}
                </span> -->

                  <v-alert
                    max-width="640"
                    border="left"
                    colored-border
                    type="info"
                    elevation="0"
                    class="mx-auto caption"
                    prominent
                    color=""
                  >
                    <span class="caption">
                      {{ $t("followUps.followUpsDescription1") }}<br />
                      {{ $t("followUps.followUpsDescription2") }}
                    </span>

                    <template slot="prepend">
                      <v-icon class="ml-4" size="28" color="mid_grey"
                        >mdi-help</v-icon
                      >
                    </template>
                  </v-alert>
                  <v-col cols="12">
                    <v-btn
                      depressed
                      color="button_blue"
                      class="white--text"
                      rounded
                      @click="dialog = true"
                      ><v-icon left small>mdi-plus</v-icon
                      >{{ $t("followUps.createFollowUp") }}
                    </v-btn>
                  </v-col>
                </v-col>
              </v-col>
            </v-row>
          </v-container>
        </template>
      </v-row>
    </v-container>

    <div style="display: flex" class="pa-0 pl-0" v-if="selectedOrganisationId">
      <!-- FILTERS AND PAGINATED TABLE -->
      <div style="width: 100%">
        <v-container fluid class="pa-0">
          <v-row
            class="pa-2"
            style="height: 60px"
            wrap
            no-gutters
            align="center"
          >
            <!-- Done/ Not done filter -->
            <v-menu bottom offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  text
                  v-bind="attrs"
                  v-on="on"
                  :color="
                    selectedFilter != selectedFilterDefault
                      ? 'button_blue'
                      : 'button_text_grey'
                  "
                  class="font-weight-medium mr-4"
                >
                  <v-icon small class="pr-1">mdi-check-circle-outline</v-icon
                  >{{ $t(staticFilters[selectedFilter].title) }}
                </v-btn>
              </template>

              <v-list dense class="py-1">
                <v-list-item
                  v-for="(item, index) in staticFilters"
                  :key="index"
                  @click="selectedFilter = index"
                >
                  <v-list-item-icon class="mr-0">
                    <v-icon small color="blue" v-if="selectedFilter == index"
                      >mdi-check</v-icon
                    >
                  </v-list-item-icon>
                  <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <!-- Projects -->
            <v-menu
              bottom
              offset-y
              min-width="240"
              v-model="showProjectFiltersMenu"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  :text="selectedProjectFilter == ''"
                  :dark="selectedProjectFilter != ''"
                  depressed
                  v-bind="attrs"
                  v-on="on"
                  :color="
                    selectedProjectFilter
                      ? selectedProjectFilter.color
                      : 'button_text_grey'
                  "
                  class="font-weight-medium mr-4"
                  :class="{
                    'ml-2': selectedProjectFilter != '',
                  }"
                >
                  <v-icon small class="pr-1">{{
                    selectedProjectFilter == ""
                      ? "mdi-folder-outline"
                      : "mdi-folder"
                  }}</v-icon
                  >{{
                    selectedProjectFilter
                      ? selectedProjectFilter.name
                      : $t("tasks.project")
                  }}
                </v-btn>
              </template>

              <v-list dense class="py-1">
                <v-list-item
                  v-for="(item, index) in projects"
                  :key="index"
                  @click="
                    selectedProjectFilter && selectedProjectFilter.id == item.id
                      ? (selectedProjectFilter = '')
                      : (selectedProjectFilter = item)
                  "
                >
                  <v-list-item-icon class="mr-0">
                    <v-icon
                      small
                      color="blue"
                      v-if="
                        selectedProjectFilter &&
                        selectedProjectFilter.id == item.id
                      "
                      >mdi-check</v-icon
                    >
                  </v-list-item-icon>
                  <v-list-item-title>
                    <v-icon small class="pr-2" :color="item.color"
                      >mdi-folder</v-icon
                    >{{ item.name }}
                  </v-list-item-title>

                  <v-list-item-icon class="my-0">
                    <v-btn
                      style="top: 2px"
                      v-if="
                        selectedProjectFilter &&
                        selectedProjectFilter.id == item.id
                      "
                      @click.stop="
                        selectedProjectFilter = '';
                        showProjectFiltersMenu = false;
                      "
                      icon
                      color="red"
                    >
                      <v-icon small color="error">mdi-close-circle</v-icon>
                    </v-btn>
                  </v-list-item-icon>
                </v-list-item>

                <!-- No projects -->
                <v-list-item v-if="projects.length == 0" disabled>
                  <v-list-item-content>
                    <v-list-item-subtitle>{{
                      $t("projects.noProjects")
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              :label="$t('tasks.search')"
              outlined
              rounded
              solo
              flat
              dense
              class="denseTextField mr-4"
              prepend-inner-icon="mdi-magnify"
              width="200"
              hide-details
              clearable
            ></v-text-field>
          </v-row>
          <v-divider class="mx-4"></v-divider>
          <v-row wrap no-gutters>
            <v-col
              cols="12"
              class="pa-0 pt-2 px-4"
              style="height: calc(100vh - 135px); overflow: auto"
            >
              <paginatedTable
                :dense="true"
                :headers="headers"
                :graphqlQuery="getGraphqlQuery()"
                :refresh="refreshIndex"
                resourceName="follow_ups"
                :forcedFooterText="$t('navigationMenu.followUps')"
                :itemsPerPageAtInit="50"
                :filter="filter"
                useCustomEmptySlot
                :customSlots="[
                  //        'status',
                  //        'start_date',
                  //        'end_date',
                  //        'favorite',
                  'name',
                  'project_id',
                  'action',
                  'attendee',
                  'owner',
                  'updated_at',
                ]"
                @clickOnRow="goToFollowUp"
                cursorAsPointer
                @noResourceFetched="noResourceFetched = true"
              >
                <template v-slot:status="{ slotScope: { item } }">
                  <v-chip small label color="light_grey">
                    <v-icon
                      size="13"
                      class="mr-2"
                      :color="getStatus(item.status).color"
                      >{{ getStatus(item.status).icon }}</v-icon
                    >
                    {{ $t("followUps.status." + item.status) }}
                  </v-chip>
                </template>

                <template v-slot:name="{ slotScope: { item } }">
                  <span class="body-3"> {{ item.name }}</span>
                </template>

                <template v-slot:start_date="{ slotScope: { item } }">
                  <span class="body-3"> {{ timeToDate(item.start_date) }}</span>
                </template>

                <template v-slot:end_date="{ slotScope: { item } }">
                  <span class="body-3"> {{ timeToDate(item.end_date) }}</span>
                </template>

                <template v-slot:updated_at="{ slotScope: { item } }">
                  <span class="caption"> {{ timeAgo(item.updated_at) }}</span>
                </template>

                <!-- <template v-slot:favorite="{}">
                  <v-simple-checkbox
                    off-icon="mdi-star-outline"
                    on-icon="mdi-star"
                    v-bind="fakeStars()"
                  >
                  </v-simple-checkbox>
                </template> -->

                <template v-slot:project_id="{ slotScope: { item } }">
                  <v-chip v-if="item.project_id" small class="light_grey">
                    <v-icon :color="item.project_color" size="13" class="mr-2"
                      >mdi-folder</v-icon
                    >
                    {{ item.project_name }}
                  </v-chip>
                </template>

                <template v-slot:attendee="{ slotScope: { item } }">
                  <userAvatarGroup
                    :limit="6"
                    :users="item.follow_up_users.map((e) => e.user)"
                    :size="24"
                  />
                </template>
                <!--
                <template v-slot:owner="{ slotScope: { item } }">
                  <userAvatar
                    :size="20"
                    :show_name_in_tooltip="true"
                    :first_name="item.user.first_name"
                    :last_name="item.user.last_name"
                    :avatar_url="item.user.avatar_url"
                    :email="item.user.email"
                  />
                </template> -->

                <template v-slot:action="{ slotScope: { item } }">
                  <v-menu bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-card>
                      <v-list dense>
                        <v-list-item @click="editFollowup(item)">
                          <v-list-item-icon class="mr-1">
                            <v-icon small>mdi-pencil</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>{{
                              $t("misc.edit")
                            }}</v-list-item-title></v-list-item-content
                          >
                        </v-list-item>

                        <v-list-item
                          @click="editFollowupProject(item)"
                          v-if="!item.project_id"
                        >
                          <v-list-item-icon class="mr-1">
                            <v-icon small>mdi-folder</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>{{
                              $t("followUps.selectProject")
                            }}</v-list-item-title></v-list-item-content
                          >
                        </v-list-item>

                        <v-list-item
                          @click="removeFromProject(item)"
                          v-if="item.project_id"
                          :disabled="projectInvisible(item.project_id)"
                        >
                          <v-list-item-icon class="mr-1">
                            <v-icon small>mdi-folder-remove</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>{{
                              $t("followUps.removeFromProject")
                            }}</v-list-item-title></v-list-item-content
                          >
                        </v-list-item>

                        <v-list-item @click="deleteFollowup(item)">
                          <v-list-item-icon class="mr-1">
                            <v-icon color="error" small>mdi-delete</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title class="error--text">{{
                              $t("misc.delete")
                            }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-menu>
                </template>
                <!-- <template v-slot:name="{ slotScope: { item } }">
                  <strong>{{ item.name }}</strong>
                </template> -->
              </paginatedTable>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </v-container>
</template>

<script>
import headerToolbar from "../shared/headerToolbar";
import projectsSidebar from "../shared/projectsSidebar";
import userAvatar from "../shared/userAvatar";
import date from "@/mixins/date";
import followup_status from "@/mixins/followup_status";
import followUpForm from "./followUpForm";
import followUpFormProject from "./followUpFormProject";
import userAvatarGroup from "@/components/shared/userAvatarGroup";
import paginatedTable from "@/components/shared/paginatedTable";
import PAGINATED_FOLLOW_UPS_INDEX from "@/graphql/follow_ups/paginated_index.gql";
import { mapActions, mapGetters } from "vuex";
import UPDATE_FOLLOW_UP from "@/graphql/follow_ups/update.gql";
import PROJECT_INDEX from "@/graphql/projects/index.gql";

export default {
  name: "Follow-ups",
  mixins: [date, followup_status],
  components: {
    followUpForm,
    headerToolbar,
    paginatedTable,
    projectsSidebar,
    userAvatar,
    userAvatarGroup,
    followUpFormProject,
  },
  data() {
    return {
      dialog: false,
      noResourceFetched: false,
      search: "",
      refreshIndex: 0,
      editedFollowup: {},
      followupFormProjectFollowup: {},
      projectsLoading: true,
      selectedProjectFilter: "",
      showProjectFiltersMenu: false, // Needed because we need to close this one programmaticaly
      projects: [],
      selectedFilterDefault: 0,
      selectedFilter: 0,
      staticFilters: [
        {
          title: "followUps.filters.allSubjects",
          value: "all",
        },
        {
          title: "followUps.filters.mySubjects",
          value: "mySubject",
        },
        {
          title: "followUps.filters.sharedWithMe",
          value: "shared",
        },
        /* ,
        {
          title: "followUps.filters.favorite",
          icon: "mdi-star",
          filter: "",
        }, */
      ],
    };
  },
  computed: {
    ...mapGetters(["selectedOrganisationId", "currentUser"]),
    miniVariant() {
      if (this.$vuetify.breakpoint.smAndDown) return true;
      return false;
    },
    headers() {
      var columns = [
        {
          text: this.$t("navigationMenu.followUps"),
          align: "start",
          sortable: true,
          value: "name",
        },
        /* {
          text: this.$t("misc.favorite"),
          align: "start",
          sortable: true,
          value: "favorite",
        }, */
        /*  {
          text: this.$t("followUps.followUpStatus"),
          align: "start",
          sortable: true,
          value: "status",
        }, */
        {
          text: this.$t("misc.projects"),
          align: "start",
          sortable: true,
          value: "project_id",
        },
        /* {
          text: this.$t("projects.projectStartDate"),
          align: "start",
          sortable: true,
          value: "start_date",
        },
        {
          text: this.$t("projects.projectEndDate"),
          align: "start",
          sortable: true,
          value: "end_date",
        }, */
        {
          text: this.$t("misc.lastActivity"),
          align: "start",
          sortable: true,
          value: "updated_at",
        },

        // {
        //   text: this.$t("misc.owner"),
        //   align: "end",
        //   sortable: false,
        //   value: "owner",
        // },
        {
          text: this.$t("misc.sharedWith"),
          align: "end",
          sortable: false,
          value: "attendee",
        },
        {
          text: this.$t("misc.actions"),
          align: "end",
          sortable: false,
          value: "action",
        },
      ];

      // Insert created columns at index 2 position when breakpoint is greater than md
      // if (!this.$vuetify.breakpoint.mdAndDown)
      //   columns.splice(2, 0, {
      //     text: this.$t("misc.createdAt"),
      //     align: this.$vuetify.breakpoint.mdAndDown ? "d-none" : "start",
      //     sortable: false,
      //     value: "created_at",
      //   });

      return columns;
    },
    filter() {
      let filter = "";
      if (this.selectedOrganisationId)
        filter = `organisation_id == '${this.selectedOrganisationId}'`;

      if (this.search) {
        filter += ` && (name like '%${this.search}%' || description like '%${this.search}%')`;
      }

      switch (this.selectedFilter) {
        case 1:
          filter += ` && follow_ups.user_id == '${this.currentUser.id}'`;
          break;
        case 2:
          filter += ` && follow_ups.user_id != '${this.currentUser.id}'`;
          break;
      }

      if (this.selectedProjectFilter && this.selectedProjectFilter.id) {
        filter += ` && project_id == '${this.selectedProjectFilter.id}'`;
      }

      return filter;
    },
  },
  methods: {
    ...mapActions(["openDestroyDialog"]),
    getProjects() {
      this.$apollo
        .query({
          query: PROJECT_INDEX,
          variables: {
            filter: `organisation_id == '${this.selectedOrganisationId}'`,
          },
        })
        .then(({ data }) => {
          // console.log("project gets");
          // console.log(data);
          this.projects = data.projects;
          this.projectsLoading = false;
        })
        .catch((error) => {
          this.sendError(error);
          console.log(error);
        });
    },
    projectInvisible(project_id) {
      if (this.projectsLoading) return true;
      return !this.projects.find((e) => e.id === project_id);
    },
    goToFollowUp(event) {
      this.$router.push({
        name: "followup_show",
        params: { id: event.id },
      });
    },
    getGraphqlQuery() {
      return PAGINATED_FOLLOW_UPS_INDEX;
    },
    onFollowUpUpdated() {
      this.dialog = false;
      this.refreshIndex++;
    },
    onFollowUpCreated() {
      this.noResourceFetched = false;
      this.dialog = false;
      this.refreshIndex++;
    },
    editFollowup(followup) {
      this.editedFollowup = {};
      this.$nextTick(() => {
        this.editedFollowup = followup;
        this.dialog = true;
      });
    },
    editFollowupProject(item) {
      this.followupFormProjectFollowup = item;
    },
    removeFromProject(item) {
      this.$apollo
        .mutate({
          mutation: UPDATE_FOLLOW_UP,
          variables: {
            id: item.id,
            name: item.name,
            project_id: null,
          },
        })
        .then(({ data }) => {
          this.sendSuccess(this.$t("followUps.removedFromProject"));
          this.onFollowUpCreated();
        })
        .catch((error) => {
          this.sendError(error);
          console.log(error);
        });
    },
    deleteFollowup(followup) {
      this.openDestroyDialog({
        id: followup.id,
        model: "follow_up",
        onDestroy: this.onFollowUpCreated,
        title: this.$t("followUps.removeAlertTitle"),
        message: this.$t("followUps.removeAlertMessage").replace(
          "<followup_name>",
          followup.name
        ),
      });
    },
    fakeStars() {
      let favorite = Math.random(1) > 0.6;

      return {
        color: favorite ? "amber" : "black",
        value: favorite,
      };
    },
  },
  created() {
    this.getProjects();
    document.title = this.$t("navigationMenu.followUps");
    this.$amplitude.getInstance().logEvent("Page: Topics");
  },
  watch: {
    search() {
      this.noResourceFetched = false;
    },
  },
};
</script>

<style >
.theme--light.v-application {
  background-color: #fff !important;
}
</style>

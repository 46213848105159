<template>
  <v-card min-width="200" flat class="px-3">
    <v-card-title class="pb-2">
      <v-row>
        
        <v-col cols="12"> <span class="subtitle-1 font-weight-bold grey--text text--darken-3">{{ $t("projects.projects") }}</span>

      <v-btn small icon dark color="grey darken-3" class="ml-1">
        <v-icon size="20">mdi-plus-circle</v-icon>
      </v-btn></v-col> </v-row>
     
    </v-card-title>

    <v-card-text class="pt-1">
      <!-- All project toggle -->
      <v-row class="py-2 pl-0">
        <v-chip
          :color="allProjectsSelected ? 'active_purple' : 'white'"
          :class="allProjectsSelected ? 'button_blue--text' : ''"
          small
          @click="clicOnAllProjects()"
        >
          <b> {{ $t("projects.allProjects") }}</b>
        </v-chip>
      </v-row>
      <v-row v-for="project in projects" :key="project" class="py-1 pl-0">
        <v-chip
          color="active_purple"
          class="button_blue--text"
          small
          v-if="selectedProjects.includes(project)"
          @click="removeProjectFromSelected(project)"
        >
          <b> {{ project }}</b>
        </v-chip>

        <v-chip
          color="white"
          small
          v-else
          @click="addProjectToSelected(project)"
        >
          {{ project }}
        </v-chip>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "Follow-ups",
  mixins: [],
  components: {},
  data() {
    return {
      projects: [
        "Personnel",
        "EcoHelmet",
        "Equipe Inno",
        "SnB Binding",
        "XC Skis",
        "Boot",
        "Semelle",
      ],
      selectedProjects: [],
      allProjectsSelected: true,
    };
  },
  methods: {
    addProjectToSelected(project) {
      this.selectedProjects.push(project);
      this.updateAllProjectsSelected();
    },
    clicOnAllProjects() {
      this.allProjectsSelected = !this.allProjectsSelected;
      if (this.allProjectsSelected) {
        this.selectedProjects = [];
      }
    },
    removeProjectFromSelected(project) {
      this.selectedProjects.splice(this.selectedProjects.indexOf(project), 1);
      this.updateAllProjectsSelected();
    },
    updateAllProjectsSelected() {
      if (this.selectedProjects.length == this.projects.length) {
        this.selectedProjects = [];
        this.allProjectsSelected = true;
      } else if (this.selectedProjects.length) {
        this.allProjectsSelected = false;
      } else {
        this.allProjectsSelected = true;
      }
    },
  },
  computed: {},
  mounted() {},
  beforeDestroy() {},
};
</script>

<style scoped>
</style>

export default {
  props: {
    graphqlQuery: {
      type: Object,
      required: true,
    },
    resourceName: {
      type: String,
      required: true,
    },
    filter: {
      type: String,
      required: false,
      default: "",
    },
    refresh: {
      type: Number,
      require: false,
    },
    itemsPerPageAtInit: {
      type: Number,
      require: false,
      default: 10
    }
  },
  data() {
    return {
      fetchedData: {
        data: [],
        total_count: 0,
      },
      loading: false,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false
      },
      reqRateLimit: 250,
      sended: false,
      sendAgain: false,
      firstFetch: true
    }
  },
  computed: {
    resources() {
      return this.fetchedData.data;
    },
    totalCount() {
      return this.fetchedData.total_count;
    },
    itemsPerPage() {
      return this.options.itemsPerPage >= 0
      ? this.options.itemsPerPage
      : this.totalCount;
    },
    orderBy() {
      if (this.options.sortBy.length > 0) {
        /* TODO Handle multisort */
        let direction = this.options.sortDesc[0] ? "DESC" : "ASC";
        return `${this.options.sortBy[0]} ${direction}`;
      }
      /* TODO use created_at  for default sorting */
      return "id DESC";
    }
  },
  methods: {
    getCurrentPage() {
      /* We want to send at most one requet per reqRateLimit */
      if (this.sended) {
        /* A request has been sended less than [reqRateLimit] ms ago */
        this.sendAgain = true;
      } else {
        /* No request sended since reqRateLimit ms */
        this.sendCurrentPageQuery();
        this.sended = true;
        /* In reqRateLimit ms reset boolean and call getCurrentPage if needed */
        setTimeout(() => {
          this.sended = false;
          if (this.sendAgain) {
            this.sendAgain = false;
            this.getCurrentPage();
          }
        }, this.reqRateLimit);
      }
    },
    sendCurrentPageQuery() {
      /* this should not be called from anywhere else than getCurrentPage() */
      /* TODO. limit at 100 per page */
      this.loading = true;
      this.$apollo
        .query({
          query: this.graphqlQuery,
          variables: {
            page: this.options.page,
            per_page: this.options.itemsPerPage,
            order_by: this.orderBy,
            filter: this.filter,
          },
        })
        .then(({ data }) => {
          this.fetchedData = data[`paginated_${this.resourceName}`];
          this.loading = false;
          if (this.firstFetch && this.fetchedData.total_count === 0) {
            console.log('noResourceFetched')
            this.$emit('noResourceFetched');
          }
          this.firstFetch = false;
        })
        .catch((error) => {
          this.sendError(error);
          console.log(error);
          this.loading = false;
        });
    }
  },
  watch: {
    options(val) {
      this.getCurrentPage();
    },
    filter() {
      this.options.page = 1;
      this.getCurrentPage();
    },
    refresh() {
      this.getCurrentPage();
    },
  },
  created() {
    this.options.itemsPerPage = this.itemsPerPageAtInit;
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"inline","position":"relative","float":"right"}},[_vm._l((_vm.firstNUsers),function(user,index){return _c('div',{key:_vm.uniqueID + '_' + user.id,staticClass:"avatarGroupContainer avatarList",style:('z-index: ' +
      (_vm.firstNUsers.length - index) +
      '; right: ' +
      (Math.min(_vm.filteredUsers.length, _vm.firstNUsers.length) - 1 - index) * -8 +
      'px;')},[_c('userAvatar',{attrs:{"size":_vm.size,"show_name_in_tooltip":true,"first_name":user.first_name,"last_name":user.last_name,"avatar_border":_vm.avatar_border,"avatar_url":user.avatar_url,"email":user.email}})],1)}),_c('v-menu',{key:_vm.uniqueID + '_menu',attrs:{"close-on-content-click":false,"offset-y":"","origin":"center center","transition":"scale-transition","disabled":_vm.disabledMenu},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [(_vm.filteredUsers.length > _vm.limit)?_c('v-btn',_vm._g(_vm._b({staticClass:"btnNumberClass ml-2",attrs:{"width":_vm.size,"height":_vm.size,"icon":""}},'v-btn',attrs,false),on),[_c('span',{staticClass:"usersNumber font-weight-bold"},[_vm._v(" +"+_vm._s(_vm.filteredUsers.length - _vm.limit)+" ")])]):_vm._e()]}}]),model:{value:(_vm.showList),callback:function ($$v) {_vm.showList=$$v},expression:"showList"}},[(_vm.showList)?_c('v-card',{staticClass:"menuContainer"},[_c('v-subheader',[_vm._v(" "+_vm._s(_vm.filteredUsers.length + " " + _vm.pluralize(_vm.$t(_vm.label), _vm.filteredUsers.length))+": ")]),_c('v-list',{staticClass:"py-0",attrs:{"dense":"","two-lines":""}},_vm._l((_vm.filteredUsers),function(user,index){return _c('v-list-item',{key:_vm.uniqueID + '_' + user.id + '_' + index},[_c('v-list-item-avatar',[_c('userAvatar',{attrs:{"size":32,"show_name_in_tooltip":false,"first_name":user.first_name,"last_name":user.last_name,"avatar_url":user.avatar_url,"email":user.email}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(user.first_name && user.last_name ? [user.first_name, user.last_name].join(" ") : user.email))]),(user.job)?_c('v-list-item-subtitle',[_c('i',{staticClass:"caption"},[_vm._v(_vm._s(user.job))])]):_c('v-list-item-subtitle',[_c('i',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t("userProfile.notSpecified")))])])],1)],1)}),1)],1):_vm._e()],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-card>
    <v-card-title class="title font-weight-regular justify-space-between">
      <span>{{ formTitle }}</span>
      <v-btn icon @click="$emit('closeDialog')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="pa-5">
      <v-text-field
        v-model="followup.name"
        :label="$t('misc.title')"
        placeholder=""
        filled
        dense
        autocomplete="off"
      />

      <v-autocomplete
        v-if="!cannotChangeProject"
        :disabled="projectsLoading"
        :loading="projectsLoading"
        v-model="followup.project_id"
        :items="projects"
        item-value="id"
        item-text="name"
        dense
        filled
        clearable
        :label="
          projectsLoading
            ? $t('followUps.loadingProjects')
            : $t('followUps.selectProject')
        "
      >
        <template v-slot:item="data">
          <v-list-item-icon>
            <customIcon
              :color="data.item.color"
              name="project"
              height="16"
              style="position: relative; top: 5px"
              ><iconProject
            /></customIcon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> {{ data.item.name }}</v-list-item-title>
          </v-list-item-content>
        </template>
      </v-autocomplete>

      <v-textarea
        v-model="followup.description"
        :label="$t('misc.description')"
        placeholder=""
        filled
        dense
        autocomplete="off"
      />

      <v-autocomplete
        v-if="!hideOwnerField && followup.id && editedFollowup.user_id === currentUser.id"
        v-model="followup.user_id"
        :items="followupUsers"
        :item-text="userName"
        item-value="id"
        dense
        filled
        :label="$t('misc.owner')"
      >
        <template v-slot:item="data">
          <div class="mr-4">
            <userAvatar
              :size="20"
              :show_name_in_tooltip="false"
              :first_name="data.item.first_name"
              :last_name="data.item.last_name"
              :avatar_url="data.item.avatar_url"
              :email="data.item.email"
            />
          </div>
          {{ userName(data.item) }}
        </template>
      </v-autocomplete>
    </v-card-text>
    <v-divider></v-divider>

    <v-card-actions>
      <v-btn text @click="$emit('closeDialog')" color="almost_black">
        <v-icon class="pr-1" small>mdi-close</v-icon>
        {{ $t("misc.cancel") }}
      </v-btn>
      <v-spacer></v-spacer>

      <v-btn
        color="success"
        class="white--text"
        depressed
        @click="saveFollowUp()"
        :disabled="saveDisabled"
      >
        {{
          followup.id
            ? $t("followUps.updateFollowUp")
            : $t("followUps.createFollowUp")
        }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import userAvatar from "../shared/userAvatar";
import CREATE_FOLLOW_UP from "@/graphql/follow_ups/create.gql";
import UPDATE_FOLLOW_UP from "@/graphql/follow_ups/update.gql";
import { mapGetters } from "vuex";
import customIcon from "@/components/custom_icons/customIcon";
import iconProject from "@/components/custom_icons/iconProject";

export default {
  name: "followUpForm",
  mixins: [],
  components: {
    userAvatar,
    customIcon,
    iconProject,
  },
  props: {
    editedFollowup: {
      type: Object,
      required: false,
      default: () => {},
    },
    hideProjectField: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideOwnerField: {
      type: Boolean,
      required: false,
      default: false,
    },
    projects: {
      type: Array,
      required: false,
      default: () => [],
    },
    projectsLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      followup: {
        id: null,
        name: "",
        description: "",
        project_id: "",
        user_id: "",
      },
    };
  },
  computed: {
    ...mapGetters(["selectedOrganisationId", "currentUser"]),
    projectInvisible() {
      if (this.projectsLoading) return true;
      if (!this.followup.project_id) return false;

      return !this.projects.find((e) => e.id === this.followup.project_id);
    },
    cannotChangeProject() {
      return this.hideProjectField || this.projectInvisible;
    },
    formTitle() {
      if (this.followup.id) return this.$t("followUps.updateFollowUp");
      return this.$t("followUps.createFollowUp");
    },
    saveDisabled() {
      return !this.followup.name;
    },
    followupUsers() {
      if (!this.followup.id) return [];
      if (!this.editedFollowup.follow_up_users) return [];
      return this.editedFollowup.follow_up_users.map((e) => e.user);
    },
  },
  methods: {
    userName(user) {
      if (user.first_name && user.last_name)
        return `${user.first_name} ${user.last_name}`;
      else return user.email;
    },
    saveFollowUp() {
      if (this.followup.id) this.upateFollowUp();
      else this.createFollowUp();
    },
    createFollowUp() {
      this.$apollo
        .mutate({
          mutation: CREATE_FOLLOW_UP,
          variables: {
            name: this.followup.name,
            description: this.followup.description,
            project_id: this.followup.project_id,
            organisation_id: this.selectedOrganisationId,
          },
        })
        .then(({ data }) => {
          this.sendSuccess(this.$t("followUps.created"));
          this.$amplitude.getInstance().logEvent("Create topic", { name: this.followup.name, id: data.create_follow_up.id });
          this.followup.name = "";
          this.followup.description = "";
          this.followup.user_id = "";
          this.followup.project_id = "";
          this.$emit("followUpCreated");
        })
        .catch((error) => {
          this.sendError(error);
          console.log(error);
        });
    },
    upateFollowUp() {
      let vars = {
        id: this.followup.id,
        name: this.followup.name,
        description: this.followup.description,
        project_id: this.followup.project_id,
      };

      if (this.cannotChangeProject) delete vars["project_id"];
      if (
        !this.hideOwnerField &&
        this.currentUser.id === this.editedFollowup.user_id &&
        this.editedFollowup.user_id != this.followup.id
      )
        vars.user_id = this.followup.user_id;

      this.$apollo
        .mutate({
          mutation: UPDATE_FOLLOW_UP,
          variables: vars,
        })
        .then(({ data }) => {
          this.sendSuccess(this.$t("followUps.updated"));
          this.initNewFollowup();
          this.$emit("followUpUpdated", data.update_follow_up);
        })
        .catch((error) => {
          this.sendError(error);
          console.log(error);
        });
    },
    initFollowup() {
      if (this.editedFollowup && this.editedFollowup.id)
        this.initEditFollowup();
      else
        this.initNewFollowup();
    },
    initEditFollowup() {
      if (this.editedFollowup.id) {
        this.followup = {
          id: this.editedFollowup.id,
          name: this.editedFollowup.name,
          description: this.editedFollowup.description,
          project_id: this.editedFollowup.project_id,
          user_id: this.editedFollowup.user_id,
        };
      }
    },
    initNewFollowup() {
      this.followup = {
        id: null,
        name: "",
        description: "",
        project_id: "",
        user_id: "",
      };
    },
  },
  created() {
    this.initFollowup();
  },
  watch: {
    editedFollowup() {
      this.initFollowup();
    },
  },
};
</script>


import moment from "moment-timezone";
export default {
  computed: {
    timezone() {
      var tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return tz;
    },
    now() {
      return moment()
        .utc()
        .valueOf();
    },
    oneYearAgo() {
      return moment()
        .utc()
        .subtract(1, "years")
        .valueOf();
    }
  },
  methods: {
    getDayName(date, format) {
      if (format)
        return moment(date, format).format("dddd");
      return moment(date).format("dddd");
    },
    getDayNumberAndMonthName(date) {
      return [moment(date).format("DD"), moment(date).format("MMMM")];
    },
    allOccurenceOfADayInAMonth(date, format) {
      var retArray = [];

      var dayToSearch = moment(date)
        .startOf('month')
        .day(this.getDayName(date, format));
      if (dayToSearch.date() > 7) dayToSearch.add(7, 'd');
      var month = dayToSearch.month();
      while (month === dayToSearch.month()) {
        retArray.push(dayToSearch.format(format));
        dayToSearch.add(7, 'd');
      }
      return retArray;
    },
    addMonths(strDate, numberOfMonths) {
      return moment(strDate).add(numberOfMonths, 'M').startOf('month');
    },
    dateNow() {
      return moment().utc().local();
    },
    addDays(strDate, numberOfDays) {
      return moment(strDate).add(numberOfDays, 'days');
    },
    weekNumber(strDate) {
      return moment(strDate).isoWeek();
    },
    monthYear(strDate) {
      return moment(strDate).format("MMMM YYYY");
    },
    fullDateAsText(strDate) {
      return moment(strDate).format("dddd DD MMMM YYYY");
    },
    addMinutesToTime(startTime, durationInMinutes) {
      return moment(startTime, 'HH:mm').add(durationInMinutes, 'minutes').format('HH:mm');
    },
    timeToUsDate(time) {
      return moment.utc(time, "YYYY-MM-DD HH:mm:ss").tz(this.timezone).format('YYYY-MM-DD');
    },
    simpleDate(time) {
      if (time.length > 10) time = time.substr(0, 10);
      return moment(time).format("YYYY-MM-DD");
    },
    hoursMinutes(time) {
      if (time.length > 10) time = time.substr(0, 10);
      return moment(time).format("HH:mm");
    },
    startOfTheYear() {
      return moment().startOf('year');
    },
    firstDayOfYear(year) {
      return moment().year(year).startOf('year').format('YYYY-MM-DD');
    },
    lastDayOfYear(year) {
      return moment().year(year).endOf('year').format('YYYY-MM-DD');
    },
    monthsAgo(nMonths) {
      return moment().utc().subtract(nMonths, 'months').valueOf();
    },
    yearsAgo(nYears) {
      return moment().utc().subtract(nYears, 'years').valueOf();
    },
    timeToDate(time) {
      if (time.length > 10) time = time.substr(0, 10);
      return moment(time).format("DD/MM/YYYY");
    },
    isDateInThePast(strDate) {
      let now = moment().utc().tz(this.timezone)
      return moment.utc(strDate, "YYYY-MM-DD HH:mm:ss").tz(this.timezone) < now;
    },
    UTCDate(strDate) {
      return moment.utc(strDate, "YYYY-MM-DD HH:mm:ss");
    },
    dateToMonth(strDate) {
      return moment(strDate).month();
    },
    olderDate(strDate1, strDate2) {
      return moment(strDate1) < moment(strDate2) ? strDate1 : strDate2;
    },
    friendlyDate(date) {
      var ret;
      if (typeof date == "string")
        ret = moment.utc(date, "YYYY-MM-DD HH:mm:ss");
      else ret = moment(date);

      return ret.format("DD MMM YY");
    },
    friendlyDateWithoutDay(date) {
      var ret;
      if (typeof date == "string")
        ret = moment.utc(date, "YYYY-MM-DD HH:mm:ss");
      else ret = moment(date);

      return ret.format("MMM YYYY");
    },
    strToTimestamp(str, format) {
      return moment(str, format);
    },
    secondElapsedSince(str) {
      let date = moment.utc(str);
      console.log('sst : ' + date + ' ' + date.format("HH:mm:ss"));
      let now = moment().utc();
      console.log('now : ' + now + ' ' + now.format("HH:mm:ss"));
      let diff = now - date;
      console.log('millisecond elapsed since last server update: ' + diff);
      if (diff < 0) console.error('diff should not be negative. is locale well set ?');
      return diff;
    },
    formatStrDate(str) {
      return moment(str, "YYYY-MM-DD HH:mm:ss").utc().tz(this.timezone).format();
    },
    strToMintutesFromMidnight(str) {
      let time = moment.utc(str, "YYYY-MM-DD HH:mm:ss").tz(this.timezone)
      return (time.hours() * 60) + time.minutes();
    },
    timeAgoIfLessThanADay(date) {
      let datetime = moment.utc(date, "YYYY-MM-DD HH:mm:ss").tz(this.timezone)
      let now = moment().utc().tz(this.timezone)

      if (now.diff(datetime, 'hours', true) >= 24)
        return this.friendlyDate(date);
      else
        return this.timeAgo(date);
    },
    timeAgo(date) {
      return moment
        .utc(date, "YYYY-MM-DD HH:mm:ss")
        .startOf("min")
        .fromNow();
    },
    dateAsText(date) {
      if (date.length > 10) date = date.substr(0, 10);
      return moment(date).format("dddd, LL");
    },
    dateAsTextShort(date) {
      if (date.length > 10) date = date.substr(0, 10);
      return moment(date).format("LL");
    }
  },
  filters: {
    yearOnly(date) {
      return moment.utc(date, "YYYY-MM-DD HH:mm:ss").format("YYYY");
    },
    friendlyDate(date) {
      if (!date) return "";
      var ret;
      if (typeof date == "string")
        ret = moment.utc(date, "YYYY-MM-DD HH:mm:ss");
      else ret = moment(date);

      return ret.format("DD MMM YY");
    },
    friendlyDateHour(date) {
      if (!date) return "";
      var ret;
      if (typeof date == "string")
        ret = moment.utc(date, "YYYY-MM-DD HH:mm:ss");
      else ret = moment(date);

      return ret.format("DD MMM HH:mm");
    },
    friendlyDateFullYear(date) {
      return moment.utc(date, "YYYY-MM-DD HH:mm:ss").format("DD MMM YYYY");
    },
    timeAgo(date) {
      return moment
        .utc(date, "YYYY-MM-DD HH:mm:ss")
        .startOf("min")
        .fromNow();
    },
    onlyTime(date) {
      return moment(date).format("HH:mm");
    }
  },
  mounted() {
    moment.locale(this.$i18n.locale);
  }
};

<template>
  <v-dialog v-model="dialog" max-width="480" transition="dialog-top-transition">
    <v-card>
      <v-card-title class="title font-weight-regular justify-space-between">
        <span>{{ $t("followUps.selectProject") }}</span>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pa-5">
        <v-autocomplete
          :disabled="projectsLoading"
          :loading="projectsLoading"
          v-model="followup.project_id"
          :items="projects"
          item-value="id"
          item-text="name"
          dense
          filled
          clearable
          :label="
            projectsLoading
              ? $t('followUps.loadingProjects')
              : $t('followUps.selectProject')
          "
        >
          <template v-slot:item="data">
            <v-list-item-icon>
              <customIcon
                :color="data.item.color"
                name="project"
                height="16"
                style="position: relative; top: 5px"
                ><iconProject
              /></customIcon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> {{ data.item.name }}</v-list-item-title>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn text @click="dialog = false" color="almost_black">
          <v-icon class="pr-1" small>mdi-close</v-icon>
          {{ $t("misc.cancel") }}
        </v-btn>
        <v-spacer></v-spacer>

        <v-btn
          color="success"
          class="white--text"
          depressed
          @click="saveFollowUp()"
          :disabled="!followup.project_id"
        >
          {{
            followup.id
              ? $t("followUps.updateFollowUp")
              : $t("followUps.createFollowUp")
          }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import userAvatar from "../shared/userAvatar";
import CREATE_FOLLOW_UP from "@/graphql/follow_ups/create.gql";
import UPDATE_FOLLOW_UP from "@/graphql/follow_ups/update.gql";
import PROJECT_INDEX from "@/graphql/projects/index.gql";
import customIcon from "@/components/custom_icons/customIcon";
import iconProject from "@/components/custom_icons/iconProject";
export default {
  name: "followUpProjectForm",
  mixins: [],
  components: { userAvatar, customIcon, iconProject },
  props: {
    editedFollowup: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      dialog: false,
      followup: {
        id: null,
        name: "",
        description: "",
        project_id: "",
      },
      projectsLoading: true,
      projects: [],
    };
  },
  computed: {
    formTitle() {
      if (this.followup.id) return this.$t("followUps.updateFollowUp");
      return this.$t("followUps.createFollowUp");
    },
  },
  methods: {
    saveFollowUp() {
      if (this.followup.id) this.upateFollowUp();
      else this.createFollowUp();
    },
    createFollowUp() {
      this.$apollo
        .mutate({
          mutation: CREATE_FOLLOW_UP,
          variables: {
            name: this.followup.name,
            description: this.followup.description,
            project_id: this.followup.project_id,
          },
        })
        .then(({ data }) => {
          this.sendSuccess(this.$t("followUps.created"));
          this.dialog = false;
          this.followup.name = "";
          this.followup.description = "";
          this.$emit("followUpCreated");
        })
        .catch((error) => {
          this.sendError(error);
          console.log(error);
        });
    },
    upateFollowUp() {
      this.$apollo
        .mutate({
          mutation: UPDATE_FOLLOW_UP,
          variables: {
            id: this.followup.id,
            name: this.followup.name,
            description: this.followup.description,
            project_id: this.followup.project_id,
          },
        })
        .then(({ data }) => {
          this.sendSuccess(this.$t("followUps.updated"));
          this.dialog = false;
          this.initNewFollowup();
          this.$emit("followUpCreated");
        })
        .catch((error) => {
          this.sendError(error);
          console.log(error);
        });
    },
    initEditFollowup() {
      if (this.editedFollowup.id) {
        this.followup = {
          id: this.editedFollowup.id,
          name: this.editedFollowup.name,
          description: this.editedFollowup.description,
          project_id: this.editedFollowup.project_id,
        };
      }
    },
    initNewFollowup() {
      this.followup = {
        id: null,
        name: "",
        description: "",
        project_id: "",
      };
    },
    getProjects() {
      this.$apollo
        .query({
          query: PROJECT_INDEX,
        })
        .then(({ data }) => {
          console.log("project gets");
          console.log(data);
          this.projects = data.projects;
          this.projectsLoading = false;
        })
        .catch((error) => {
          this.sendError(error);
          console.log(error);
        });
    },
  },
  created() {
    this.getProjects();
    this.initNewFollowup();
  },
  watch: {
    editedFollowup() {
      this.initEditFollowup();
      this.dialog = true;
    },
  },
};
</script>


<template>
  <div>
    <!--
    <v-skeleton-loader
      v-if="loading"
      :type="'table: heading@' + headers.length + ', table-thead, table-row-divider@' + pagination.itemsPerPage +', table-tfoot'"
    ></v-skeleton-loader> -->
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="resources"
      :fixed-header="fixedHeader"
      :options.sync="options"
      :server-items-length="totalCount"
      @click:row="handleRowClick"
      :height="height"
      ripple
      :dense="dense"
      :class="{ 'row-pointer': cursorAsPointer }"
      :footer-props="{
        'items-per-page-text': pluralize(camelize(forcedFooterText ? forcedFooterText : resourceName, ' '), 2),
        'items-per-page-options': [10, 20, 50, 100, -1]
      }"
    >


      <template
        v-for="customSlot in customSlots"
        v-slot:[`item.${customSlot}`]="{ item }"
      >
        <slot :name="customSlot" :slot-scope="{ item }"></slot>
      </template>

      <template slot="no-data">
        <slot name="no-data"></slot>
      </template>

      <!-- <v-alert slot="no-results" :value="true" color="error" icon="warning"
          >Your search for "{{ search }}" found no results.</v-alert
        > -->
    </v-data-table>
  </div>
</template>

<script>
import pluralize from "pluralize";
import pagination from "@/mixins/pagination";

export default {
  name: "paginatedTable",
  components: {},
  mixins: [pagination],
  props: {
    customSlots: {
      type: Array,
      required: false,
      default: [],
    },
    headers: {
      type: Array,
      required: true,
    },
    cursorAsPointer: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: true,
      required: false,
    },
    fixedHeader: {
      type: Boolean,
      default: false,
      required: false,
    },
    height: {
      type: [String, Number],
      default: "100%",
      required: false,
    },
    forcedFooterText: {
      type: String,
      default: "",
      required: false
    }

  },
  data() {
    return {
      pluralize,
    };
  },
  methods: {
    handleRowClick(event) {
      this.$emit("clickOnRow", event);
    },
    camelize(str, sep = "", plural = false) {
      return pluralize(
        str
          .split("_")
          .map((w) => w.replace(/./, (m) => m.toUpperCase()))
          .join(sep),
        plural ? 2 : 1
      );
    },
  },
};
</script>


<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>

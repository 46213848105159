export default {
  data() {
    return {
      status_list: [
        {
          value: "done",
          color: "success",
          icon: "mdi-check"
        },
        {
          value: "pending",
          color: "amber",
          icon: "mdi-pause"
        },
        {
          value: "progress",
          color: "blue",
          icon: "mdi-transfer-right"
        },
        {
          value: "late",
          color: "error",
          icon: "mdi-calendar-clock"
        },
        {
          value: "stuck",
          color: "pink",
          icon: "mdi-alert"
        },
        {
          value: "review",
          color: "button_blue",
          icon: "mdi-magnify"
        },
        {
          value: "canceled",
          color: "black",
          icon: "mdi-cancel"
        }
      ],
    };
  },
  methods: {
    getStatus(status) {
      return this.status_list.find(s => s.value == status)
    }
  }
};

<template>
  <div style="display: inline; position: relative;  float: right">
    <div
      v-for="(user, index) in firstNUsers"
      :key="uniqueID + '_' + user.id"
      class="avatarGroupContainer avatarList"
      :style="
        'z-index: ' +
        (firstNUsers.length - index) +
        '; right: ' +
        (Math.min(filteredUsers.length, firstNUsers.length) - 1 - index) * -8 +
        'px;'
      "
    >
      <userAvatar
        :size="size"
        :show_name_in_tooltip="true"
        :first_name="user.first_name"
        :last_name="user.last_name"
        :avatar_border="avatar_border"
        :avatar_url="user.avatar_url"
        :email="user.email"
      />
    </div>

    <v-menu
      :close-on-content-click="false"
      :key="uniqueID + '_menu'"
      offset-y
      origin="center center"
      transition="scale-transition"
      v-model="showList"
      :disabled="disabledMenu"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :width="size"
          :height="size"
          icon
          class="btnNumberClass ml-2"
          v-bind="attrs"
          v-on="on"
          v-if="filteredUsers.length > limit"
        >
          <span class="usersNumber font-weight-bold">
            +{{ filteredUsers.length - limit }}
          </span>
        </v-btn>
      </template>
      <v-card class="menuContainer" v-if="showList">
        <v-subheader>
          {{ filteredUsers.length + " " + pluralize($t(label), filteredUsers.length) }}:
        </v-subheader>
        <v-list dense two-lines class="py-0">
          <v-list-item
            v-for="(user, index) in filteredUsers"
            :key="uniqueID + '_' + user.id + '_' + index"
          >
            <v-list-item-avatar>
              <userAvatar
                :size="32"
                :show_name_in_tooltip="false"
                :first_name="user.first_name"
                :last_name="user.last_name"
                :avatar_url="user.avatar_url"
                :email="user.email"
              />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{
                user.first_name && user.last_name
                  ? [user.first_name, user.last_name].join(" ")
                  : user.email
              }}</v-list-item-title>
              <v-list-item-subtitle v-if="user.job"
                ><i class="caption">{{ user.job }}</i></v-list-item-subtitle
              >
              <v-list-item-subtitle v-else
                ><i class="caption">{{
                  $t("userProfile.notSpecified")
                }}</i></v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import userAvatar from "../shared/userAvatar";
import pluralize from "pluralize";

const uuidv4 = require("uuid/v4");
export default {
  name: "userAvatarGroup",

  components: { userAvatar },
  props: {
    users: {
      type: Array,
    },
    limit: {
      type: Number,
      default: 5,
      required: false,
    },
    size: {
      type: Number,
      default: 32,
    },
    label: {
      type: String,
      default: "misc.attendee",
      required: false,
    },
    disabledMenu: {
      type: Boolean,
      default: false,
    },
    avatar_border: {
      type: Boolean,
      default: true,
      required: false,
    },
    btnNumberClass: {
      type: String,
      default: "light_grey mid_grey--text",
    },
  },
  data() {
    return {
      uniqueID: uuidv4(),
      pluralize,
      showList: false,
    };
  },
  methods: {},
  computed: {
    usersLength() {
      return this.filteredUsers.length;
    },
    firstNUsers() {
      return this.filteredUsers.slice(0, this.limit);
    },
    filteredUsers() {
      return this.users.filter(u => { return  u != null})
    }
  },
  mounted() {
    // console.log(this.filteredUsers)
  },
  watch: {
    usersLength(old_value, new_value) {
      if (new_value != old_value) {
        this.uniqueID = uuidv4();
      }
    },
  },
};
</script>

<style scoped>
.avatarGroupContainer:hover {
  transform: translateY(-4px) scale(1.5);
}

.menuContainer {
  max-height: 416px;
  overflow-y: auto;
  width: min-content;
  padding-right: 36px;
}

.usersNumber {
  font-size: 0.9em;
}

.avatarList {
  display: inline-table;
  position: relative;
}
</style>
